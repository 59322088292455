import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { service } from "api/transportRequest";
import {
  ITransportRequest,
  ITransportRequestStatusLine,
} from "api/transportRequest";
import { AppDispatch, RootState } from "redux/store";
import {
  validateAsync as validateAsyncApi,
  refuseAsync as refuseAsyncApi,
  addCommentAsync as addCommentAsyncApi,
} from "api/transportRequest";

export interface ITransportRequestState {
  busy?: boolean;
  error?: string;
  entity?: ITransportRequest;
}

export const initialState: ITransportRequestState = {
  busy: false,
  error: "",
  entity: undefined,
};

const getTransportRequestStateFromTransportRequest = (
  transportRequest: ITransportRequest
): ITransportRequestState => {
  return {
    ...initialState,
    entity: transportRequest,
  };
};

export const getAsync = createAsyncThunk<
  ITransportRequestState,
  string,
  {
    dispatch: AppDispatch;
    state: RootState;
  }
>("transportRequest/getAsync", async (input, thunkApi) => {
  const transportRequest: ITransportRequest = (await service.get({ id: input }))
    .result;
  const transportRequestState =
    getTransportRequestStateFromTransportRequest(transportRequest);
  return transportRequestState;
});

export const updateAsync = createAsyncThunk<
  ITransportRequestState,
  ITransportRequest,
  {
    dispatch: AppDispatch;
    state: RootState;
  }
>("transportRequest/updateAsync", async (input, thunkApi) => {
  const transportRequest: ITransportRequest = (
    await service.update({
      ...input,
    })
  ).result;
  const transportRequestState =
    getTransportRequestStateFromTransportRequest(transportRequest);
  return transportRequestState;
});

export const validateAsync = createAsyncThunk<
  ITransportRequestState,
  ITransportRequest,
  {
    dispatch: AppDispatch;
    state: RootState;
  }
>("transportRequest/validateAsync", async (input, thunkApi) => {
  const transportRequest: ITransportRequest = (await validateAsyncApi(input))
    .data.result;
  const transportRequestState =
    getTransportRequestStateFromTransportRequest(transportRequest);
  return transportRequestState;
});

export const refuseAsync = createAsyncThunk<
  ITransportRequestState,
  ITransportRequest,
  {
    dispatch: AppDispatch;
    state: RootState;
  }
>("transportRequest/refuseAsync", async (input, thunkApi) => {
  const transportRequest: ITransportRequest = (await refuseAsyncApi(input)).data
    .result;
  const transportRequestState =
    getTransportRequestStateFromTransportRequest(transportRequest);
  return transportRequestState;
});

export const addCommentAsync = createAsyncThunk<
  ITransportRequestStatusLine,
  { transportRequestId: string; comment: string },
  {
    dispatch: AppDispatch;
    state: RootState;
  }
>("transportRequest/addCommentAsync", async (input, thunkApi) => {
  const result: ITransportRequestStatusLine = (await addCommentAsyncApi(input))
    .data.result;
  return result;
});

const transportRequestSlice = createSlice({
  name: "transportRequest",
  initialState: initialState, // for TypeScript check
  reducers: {
    reset: (state) => {
      let newState = { ...initialState };
      return newState;
    },
    updateState: (
      state,
      action: PayloadAction<Partial<ITransportRequestState>>
    ) => {
      return { ...state, ...action.payload };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAsync.pending, (state, action) => {
      state.busy = true;
      state.error = undefined;
    });
    builder.addCase(getAsync.rejected, (state, action) => {
      state.busy = false;
      state.error = action.error.message;
    });
    builder.addCase(getAsync.fulfilled, (state, action) => {
      return {
        ...action.payload,
        busy: false,
        error: undefined,
      };
    });
    builder.addCase(updateAsync.pending, (state, action) => {
      state.busy = true;
      state.error = undefined;
    });
    builder.addCase(updateAsync.rejected, (state, action) => {
      state.busy = false;
      state.error = action.error.message;
    });
    builder.addCase(updateAsync.fulfilled, (state, action) => {
      return {
        ...action.payload,
        busy: false,
        error: undefined,
      };
    });
    builder.addCase(addCommentAsync.pending, (state, action) => {
      state.busy = true;
      state.error = undefined;
    });
    builder.addCase(addCommentAsync.rejected, (state, action) => {
      state.busy = false;
      state.error = action.error.message;
    });
    builder.addCase(addCommentAsync.fulfilled, (state, action) => {
      state.entity?.statusLines?.unshift(action.payload);
      state.busy = false;
      state.error = undefined;
    });
    builder.addCase(validateAsync.pending, (state, action) => {
      state.busy = true;
      state.error = undefined;
    });
    builder.addCase(validateAsync.rejected, (state, action) => {
      state.busy = false;
      state.error = action.error.message;
    });
    builder.addCase(validateAsync.fulfilled, (state, action) => {
      return {
        ...action.payload,
        busy: false,
        error: undefined,
      };
    });
    builder.addCase(refuseAsync.pending, (state, action) => {
      state.busy = true;
      state.error = undefined;
    });
    builder.addCase(refuseAsync.rejected, (state, action) => {
      state.busy = false;
      state.error = action.error.message;
    });
    builder.addCase(refuseAsync.fulfilled, (state, action) => {
      return {
        ...action.payload,
        busy: false,
        error: undefined,
      };
    });
  },
});

export const { reset, updateState } = transportRequestSlice.actions;
export default transportRequestSlice.reducer;
