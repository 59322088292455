import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ICompany } from "api/company";
import { IConfiguration } from "api/configuration";
import { IDepth } from "api/depth";
import { IFurniture } from "api/furniture";
import { IMerch } from "api/merch";
import { IOrder, IOrderStatusLine, OrderStatusEnum, service } from "api/order";
import { IProduct } from "api/product";
import { IStoreDto } from "api/store";
import { ITempFile } from "helpers/files";
import { AppDispatch, RootState } from "redux/store";
import { IReferenceItem } from "types/IReferenceItem";
import { IUser } from "types/IUser";

export interface IOrderState {
  step: StepEnum;
  busy: boolean;
  error?: string;
  id?: string;
  creationTime?: string;
  creatorUser?: IUser;
  status?: OrderStatusEnum;
  reference?: string;
  statusLines?: IOrderStatusLine[];
  company?: ICompany;
  furniture?: IFurniture;
  depth?: IDepth;
  configuration?: IConfiguration;
  merch?: IMerch;
  store?: IStoreDto;
  storeReference?: string;
  storeLabel?: string;
  address?: string;
  city?: string;
  postalCode?: string;
  contactEmail?: string;
  contactName?: string;
  contactPhoneNumber?: string;
  linearGain: string;
  lastImplantation?: string;
  expectedDeliveryDate?: string;
  signatureName?: string;
  signature?: string;
  orderFormSignedTemp?: ITempFile;
  orderFormId?: string;
  orderFormSignedId?: string;
  deliveryFormId?: string;
  markupFileId?: string;
  completionDate?: string;
  factoryRequestId?: string;
  transportRequestId?: string;
  orderLines?: IOrderLine[];
  isGoodDeliveryDate?: boolean;
  totalPrice?: number;
}

export interface IOrderLine {
  orderId: string;
  product: IProduct;
  productId: string;
  quantity: number;
  unitPrice: number;
  total: number;
  merchId?: string;
}

export enum StepEnum {
  Store = 0, // used in <Stepper />
  Merch,
  AdditionalProducts,
  ExpectedDeliveryDate,
  Signature,
  Validation,
}

export const initialState: IOrderState = {
  step: StepEnum.Store,
  busy: false,
} as IOrderState;

const getOrderStateFromOrder = (order: IOrder): IOrderState => {
  return {
    ...initialState,
    ...order,
    status: order.orderStatus,
    statusLines: order.orderStatusLines,
    company: order.merch?.company,
    furniture: order.merch?.furniture,
    depth: order.merch?.depth,
    configuration: order.merch?.configuration,
    totalPrice: order.totalHT,
  };
};

export const getAsync = createAsyncThunk<
  IOrderState,
  string,
  {
    dispatch: AppDispatch;
    state: RootState;
  }
>("order/getAsync", async (input, thunkApi) => {
  const order: IOrder = (await service.get({ id: input })).result;
  const orderState = getOrderStateFromOrder(order);
  return orderState;
});

export const getReferencesAsync = createAsyncThunk<
  IReferenceItem[],
  void,
  {
    dispatch: AppDispatch;
    state: RootState;
  }
>("order/getReferencesAsync", async (input, thunkApi) => {
  const items: IReferenceItem[] = (await service.invoke("get", "getReferences"))
    .result;
  return items;
});

export const createAsync = createAsyncThunk<
  IOrderState,
  IOrderState,
  {
    dispatch: AppDispatch;
    state: RootState;
  }
>("order/createAsync", async (input, thunkApi) => {
  const order = (await service.create({ ...input, storeId: input.store?.id }))
    .result;
  const orderState = getOrderStateFromOrder(order);
  return orderState;
});

export const updateAsync = createAsyncThunk<
  IOrderState,
  IOrderState,
  {
    dispatch: AppDispatch;
    state: RootState;
  }
>("order/updateAsync", async (input, thunkApi) => {
  const order: IOrder = (
    await service.update({
      ...input,
      storeId: input.store?.id,
      merchId: input.merch?.id,
    })
  ).result;
  const orderState = getOrderStateFromOrder(order);
  return orderState;
});

export const completeAsync = createAsyncThunk<
  IOrderState,
  IOrderState,
  {
    dispatch: AppDispatch;
    state: RootState;
  }
>("order/completeAsync", async (input, thunkApi) => {
  const order: IOrder = (await service.invoke("put", "Complete", input)).result;
  const orderState = getOrderStateFromOrder(order);
  return orderState;
});

export const validateAsync = createAsyncThunk<
  IOrderState,
  IOrderState,
  {
    dispatch: AppDispatch;
    state: RootState;
  }
>("order/validateAsync", async (input, thunkApi) => {
  const order: IOrder = (await service.invoke("put", "Validate", input)).result;
  const orderState = getOrderStateFromOrder(order);
  return orderState;
});

export const refuseAsync = createAsyncThunk<
  IOrderState,
  IOrderState,
  {
    dispatch: AppDispatch;
    state: RootState;
  }
>("order/refuseAsync", async (input, thunkApi) => {
  const order: IOrder = (await service.invoke("put", "Refuse", input)).result;
  const orderState = getOrderStateFromOrder(order);
  return orderState;
});

export const addCommentAsync = createAsyncThunk<
  IOrderStatusLine,
  { orderId: string; comment: string; sendNotification?: boolean },
  {
    dispatch: AppDispatch;
    state: RootState;
  }
>("order/addCommentAsync", async (input, thunkApi) => {
  const result: IOrderStatusLine = (
    await service.invoke("post", "AddComment", input)
  ).result;
  return result;
});

export const updateStatusAsync = createAsyncThunk<
  IOrderStatusLine,
  { orderId: string; status: number; sendNotification?: boolean },
  {
    dispatch: AppDispatch;
    state: RootState;
  }
>("order/updateStatusAsync", async (input, thunkApi) => {
  const result: IOrderStatusLine = (
    await service.invoke("post", "UpdateStatus", input)
  ).result;
  return result;
});

const orderSlice = createSlice({
  name: "order",
  initialState: initialState, // for TypeScript check
  reducers: {
    reset: (state) => {
      let newState = { ...initialState };
      return newState;
    },
    updateCompany: (state, action: PayloadAction<ICompany>) => {
      if (state.company?.id !== action.payload.id) {
        state.furniture = undefined;
        state.depth = undefined;
        state.configuration = undefined;
        state.merch = undefined;
      }
      state.company = action.payload;
    },
    updateFurniture: (state, action: PayloadAction<IFurniture>) => {
      if (state.furniture?.id !== action.payload.id) {
        state.depth = undefined;
        state.configuration = undefined;
        state.merch = undefined;
      }
      state.furniture = action.payload;
    },
    updateDepth: (state, action: PayloadAction<IDepth>) => {
      if (state.depth?.id !== action.payload.id) {
        state.configuration = undefined;
        state.merch = undefined;
      }
      state.depth = action.payload;
    },
    updateConfiguration: (state, action: PayloadAction<IConfiguration>) => {
      if (state.configuration?.id !== action.payload.id) {
        state.merch = undefined;
      }
      state.configuration = action.payload;
    },
    updateMerch: (state, action: PayloadAction<IMerch>) => {
      state.merch = action.payload;
    },
    updateStore: (state, action: PayloadAction<IStoreDto>) => {
      state.store = action.payload;
    },
    updateStep: (state, action: PayloadAction<StepEnum>) => {
      state.step = action.payload;
    },
    updateSignatureName: (state, action: PayloadAction<string>) => {
      state.signatureName = action.payload;
    },
    updateSignature: (state, action: PayloadAction<string | undefined>) => {
      state.signature = action.payload;
    },
    updateState: (state, action: PayloadAction<Partial<IOrderState>>) => {
      return { ...state, ...action.payload };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAsync.pending, (state, action) => {
      state.busy = true;
      state.error = undefined;
    });
    builder.addCase(getAsync.rejected, (state, action) => {
      state.busy = false;
      state.error = action.error.message;
    });
    builder.addCase(getAsync.fulfilled, (state, action) => {
      return {
        ...action.payload,
        step:
          action.payload.status === OrderStatusEnum.Draft
            ? StepEnum.Merch
            : action.payload.status === OrderStatusEnum.Signature
            ? StepEnum.Signature
            : StepEnum.Validation,
        busy: false,
        error: undefined,
      };
    });
    builder.addCase(createAsync.pending, (state, action) => {
      state.busy = true;
      state.error = undefined;
    });
    builder.addCase(createAsync.rejected, (state, action) => {
      state.busy = false;
      state.error = action.error.message;
    });
    builder.addCase(createAsync.fulfilled, (state, action) => {
      return {
        ...action.payload,
        busy: false,
        error: undefined,
      };
    });
    builder.addCase(updateAsync.pending, (state, action) => {
      state.busy = true;
      state.error = undefined;
    });
    builder.addCase(updateAsync.rejected, (state, action) => {
      state.busy = false;
      state.error = action.error.message;
    });
    builder.addCase(updateAsync.fulfilled, (state, action) => {
      return {
        ...action.payload,
        step:
          state.step === StepEnum.Store
            ? StepEnum.Merch
            : state.step === StepEnum.Merch
            ? StepEnum.AdditionalProducts
            : state.step === StepEnum.AdditionalProducts
            ? StepEnum.ExpectedDeliveryDate
            : state.step === StepEnum.ExpectedDeliveryDate
            ? StepEnum.Signature
            : StepEnum.Validation,
        busy: false,
        error: undefined,
      };
    });
    builder.addCase(completeAsync.pending, (state, action) => {
      state.busy = true;
      state.error = undefined;
    });
    builder.addCase(completeAsync.rejected, (state, action) => {
      state.busy = false;
      state.error = action.error.message;
    });
    builder.addCase(completeAsync.fulfilled, (state, action) => {
      return {
        ...action.payload,
        step: StepEnum.Validation,
        busy: false,
        error: undefined,
      };
    });
    builder.addCase(addCommentAsync.pending, (state, action) => {
      state.busy = true;
      state.error = undefined;
    });
    builder.addCase(addCommentAsync.rejected, (state, action) => {
      state.busy = false;
      state.error = action.error.message;
    });
    builder.addCase(addCommentAsync.fulfilled, (state, action) => {
      state.statusLines?.unshift(action.payload);
      state.busy = false;
      state.error = undefined;
    });
    builder.addCase(updateStatusAsync.pending, (state, action) => {
      state.busy = true;
      state.error = undefined;
    });
    builder.addCase(updateStatusAsync.rejected, (state, action) => {
      state.busy = false;
      state.error = action.error.message;
    });
    builder.addCase(updateStatusAsync.fulfilled, (state, action) => {
      state.statusLines?.unshift(action.payload);
      state.status = action.payload.orderStatus;
      state.busy = false;
      state.error = undefined;
    });
    builder.addCase(validateAsync.pending, (state, action) => {
      state.busy = true;
      state.error = undefined;
    });
    builder.addCase(validateAsync.rejected, (state, action) => {
      state.busy = false;
      state.error = action.error.message;
    });
    builder.addCase(validateAsync.fulfilled, (state, action) => {
      return {
        ...action.payload,
        step: StepEnum.Validation,
        busy: false,
        error: undefined,
      };
    });
    builder.addCase(refuseAsync.pending, (state, action) => {
      state.busy = true;
      state.error = undefined;
    });
    builder.addCase(refuseAsync.rejected, (state, action) => {
      state.busy = false;
      state.error = action.error.message;
    });
    builder.addCase(refuseAsync.fulfilled, (state, action) => {
      return {
        ...action.payload,
        step: StepEnum.Validation,
        busy: false,
        error: undefined,
      };
    });
  },
});

export const {
  reset,
  updateCompany,
  updateConfiguration,
  updateDepth,
  updateFurniture,
  updateMerch,
  updateStore,
  updateStep,
  updateSignatureName,
  updateSignature,
  updateState,
} = orderSlice.actions;
export default orderSlice.reducer;
