import React, { useCallback, useEffect, useMemo } from "react";
import {
  Alert,
  Button,
  Grid,
  TextField,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { makeStyles, createStyles } from "@mui/styles";
import backgroundImage from "images/login_background.jpg";
import logo from "images/logo.png";
import { useNavigate } from "react-router-dom";
import { RootState, useAppDispatch } from "redux/store";
import { IRegistrationInput } from "types/IRegistration";
import { registerAsync } from "redux/actions/registration";
import { useSelector } from "react-redux";
import { ILoadingType } from "types/ILoadingType";
import { reset } from "redux/reducers/registration";
import { useFormik } from "formik";
import * as yup from "yup";

const Registration = () => {
  let navigate = useNavigate();
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { status, canLogin, error } = useSelector(
    (state: RootState) => state.registration
  );

  const initialValues: IRegistrationInput = {
    name: "",
    surname: "",
    emailAddress: "",
    username: "",
    password: "",
    confirmPassword: "",
  };

  const classes = useStyles();

  const hasFailed = useMemo(() => status === ILoadingType.failed, [status]);

  const appDispatch = useAppDispatch();

  const handleLink = useCallback(
    (path: string) => {
      navigate(path);
    },
    [navigate]
  );

  // Registration succeeded, redirect to login
  useEffect(() => {
    if (status === ILoadingType.succeeded && canLogin === true) {
      appDispatch(reset());
      handleLink("/login");
    }
  }, [appDispatch, canLogin, handleLink, status]);

  const validationSchema = yup.object({
    name: yup.string().required("Nom requis"),
    surname: yup.string().required("Prénom requis"),
    username: yup
      .string()
      .min(8, "8 caractères minimum")
      .required("Nom d'utilisateur requis"),
    emailAddress: yup
      .string()
      .email("Email non valide")
      .required("Email requis"),
    password: yup
      .string()
      .min(8, "8 caractères minimum")
      .required("Mot de passe requis"),
    confirmPassword: yup
      .string()
      .required("Confirmation du mot de passe requis")
      .oneOf(
        [yup.ref("password"), null],
        "Confirmation du mot de passe incorrect"
      ),
  });

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    validateOnChange: true, // for better performance set this to false
    onSubmit: async (values) => {
      await appDispatch(registerAsync(values));
    },
  });

  return (
    <div className={classes.container}>
      <div className={classes.containerBackground}></div>
      <div className={classes.containerBackgroundImage}></div>
      <div className={classes.contentContainer}>
        <img src={logo} className={classes.logo} alt="" />
        <form
          onSubmit={formik.handleSubmit}
          className={classes.formContainer}
          noValidate
        >
          <Typography
            variant="h4"
            component="h2"
            gutterBottom
            textAlign="center"
          >
            Inscription
          </Typography>
          <Grid
            container
            spacing={smallScreen ? 0 : 2}
            style={{ minWidth: smallScreen ? 250 : 540 }}
          >
            <Grid item xs={12} sm={6}>
              <Grid container direction={"column"}>
                <Grid item>
                  <TextField
                    required
                    fullWidth
                    label="Nom"
                    margin="normal"
                    name="surname"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.surname}
                    error={
                      formik.touched.surname && Boolean(formik.errors.surname)
                    }
                    helperText={formik.touched.surname && formik.errors.surname}
                    variant="outlined"
                  />
                </Grid>
                <Grid item>
                  <TextField
                    required
                    fullWidth
                    label="Prénom"
                    margin="normal"
                    name="name"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.name}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    helperText={formik.touched.name && formik.errors.name}
                    variant="outlined"
                  />
                </Grid>
                <Grid item>
                  <TextField
                    required
                    fullWidth
                    label="Email"
                    margin="normal"
                    name="emailAddress"
                    inputProps={{
                      autoComplete: "new-emailAddress",
                    }}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.emailAddress}
                    error={
                      formik.touched.emailAddress &&
                      Boolean(formik.errors.emailAddress)
                    }
                    helperText={
                      formik.touched.emailAddress && formik.errors.emailAddress
                    }
                    variant="outlined"
                  />
                </Grid>
                <Grid item>
                  <TextField
                    required
                    fullWidth
                    label="Identifiant"
                    margin="normal"
                    name="username"
                    inputProps={{
                      autoComplete: "new-id",
                    }}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.username}
                    error={
                      formik.touched.username && Boolean(formik.errors.username)
                    }
                    helperText={
                      formik.touched.username && formik.errors.username
                    }
                    variant="outlined"
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Grid container direction={"column"}>
                <Grid item>
                  <TextField
                    required
                    fullWidth
                    label="Mot de passe"
                    margin="normal"
                    name="password"
                    type="password"
                    inputProps={{
                      autoComplete: "new-password",
                    }}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.password}
                    error={
                      formik.touched.password && Boolean(formik.errors.password)
                    }
                    helperText={
                      formik.touched.password && formik.errors.password
                    }
                    variant="outlined"
                  />
                </Grid>
                <Grid item>
                  <TextField
                    required
                    fullWidth
                    label="Confirmation du mot de passe"
                    margin="normal"
                    name="confirmPassword"
                    type="password"
                    inputProps={{
                      autoComplete: "new-password",
                    }}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.confirmPassword}
                    error={
                      formik.touched.confirmPassword &&
                      Boolean(formik.errors.confirmPassword)
                    }
                    helperText={
                      formik.touched.confirmPassword &&
                      formik.errors.confirmPassword
                    }
                    variant="outlined"
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {hasFailed && <Alert severity="error">{error}</Alert>}
          {formik.isSubmitting && (
            <Alert severity="info">Inscription en cours...</Alert>
          )}
          <div className={classes.submitButtonContainer}>
            <Button
              color="primary"
              disabled={formik.isSubmitting}
              fullWidth
              size="large"
              variant="contained"
              type="submit"
            >
              Valider
            </Button>
          </div>
          <div className={classes.submitButtonContainer}>
            <Button
              color="primary"
              disabled={formik.isSubmitting}
              fullWidth
              size="large"
              variant="contained"
              type="button"
              onClick={(e) => {
                handleLink("/login");
              }}
            >
              Connexion
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      height: "100vh",
      width: "100vw",
      display: "flex",
    },
    containerBackground: {
      position: "fixed",
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      background: theme.palette.secondary.main,
    },
    containerBackgroundImage: {
      backgroundImage: `url(${backgroundImage})`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      position: "fixed",
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
    },
    contentContainer: {
      position: "relative",
      margin: "0 auto", // auto auto to center both vertically / horizontally
      textAlign: "center",
      padding: 10,
    },
    logo: {
      margin: 10,
      marginBottom: 50,
      height: 50,
    },
    formContainer: {
      paddingTop: 40,
      paddingBottom: 40,
      paddingLeft: 20,
      paddingRight: 20,
      backgroundColor: "white",
      borderRadius: "10px",
      boxShadow: "0 0 10px #000",
      borderColor: theme.palette.primary.main,
      border: 2,
      borderStyle: "solid",
    },
    submitButtonContainer: {
      marginTop: 20,
    },
  })
);
export default Registration;
// Registration.whyDidYouRender = {
//   logOnDifferentValues: false,
//   customName: "Registration",
// };
