import AbpAppService from "api/AbpAppService";
import { IAbpResponseGet } from "types/IApi";

const appService = new AbpAppService<any, any>("Session");

export const getCurrentLoginInformations = async (): Promise<
  IAbpResponseGet<any>
> => {
  return appService.invoke("get", "GetCurrentLoginInformations", {});
};
