import { createAsyncThunk } from "@reduxjs/toolkit";
import { getResponseExceptionMessage } from "api/apiSettings";
import { tokenAuth } from "api/authentication";
import { getCurrentLoginInformations } from "api/session";

export const loginAsync = createAsyncThunk(
  "auth/login",
  async ({ username, password }: any, thunkApi) => {
    try {
      const response = await tokenAuth(username, password);
      if (response.data.success) {
        return response.data.result;
      } else {
        const errorMsg = getResponseExceptionMessage(response?.data.error);
        throw errorMsg;
      }
    } catch (error) {
      const errorMsg = getResponseExceptionMessage(error);
      throw errorMsg;
    }
  }
);

export const loadSessionAsync = createAsyncThunk(
  "session/load",
  async (payload, thunkApi) => {
    try {
      const response = await getCurrentLoginInformations();
      if (response?.success) {
        return response.result;
      } else {
        throw response?.error;
      }
    } catch (error) {
      const errorMsg = getResponseExceptionMessage(error);
      throw errorMsg;
    }
  }
);
