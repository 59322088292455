import { Home } from "@mui/icons-material";
import LoadingScreen from "components/LoadingScreen";
import { useCallback, useMemo, Suspense, lazy } from "react";
import { useTranslation } from "react-i18next";

const Account = lazy(() => import("routes/account/Account"));
const HomeContent = lazy(() => import("routes/app/HomeContent"));
const Catalog = lazy(() => import("routes/catalog/Catalog"));

const CreateCompany = lazy(() => import("routes/catalog/companies/Create"));
const CreateConfiguration = lazy(() => import("routes/catalog/configurations/Create"));
const CreateDepth = lazy(() => import("routes/catalog/depths/Create"));
const CreateRetailOrder = lazy(() => import("routes/retailOrders/Create"));
const CreateFurniture = lazy(() => import("routes/catalog/furnitures/Create"));
const CreateMerch = lazy(() => import("routes/catalog/merchs/Create"));
const CreateOrder = lazy(() => import("routes/orders/Create"));
const CreateProduct = lazy(() => import("routes/catalog/products/Create"));
const CreateRole = lazy(() => import("routes/roles/Create"));
const CreateStore = lazy(() => import("routes/stores/Create"));
const CreateUser = lazy(() => import("routes/users/Create"));
const CreateFactory = lazy(() => import("routes/factories/Create"));
const CreateDeliverer = lazy(() => import("routes/deliverers/Create"));
const CreateAfterSaleRequest = lazy(() => import("routes/afterSaleRequests/Create"));
const CreateWidth = lazy(() => import("routes/catalog/widths/Create"));
const CreateUserToUser = lazy(() => import("routes/userToUsers/Create"));

const EditCompany = lazy(() => import("routes/catalog/companies/Edit"));
const EditConfiguration = lazy(() => import("routes/catalog/configurations/Edit"));
const EditDepth = lazy(() => import("routes/catalog/depths/Edit"));
const EditFurniture = lazy(() => import("routes/catalog/furnitures/Edit"));
const EditMerch = lazy(() => import("routes/catalog/merchs/Edit"));
const EditProduct = lazy(() => import("routes/catalog/products/Edit"));
const EditStore = lazy(() => import("routes/stores/Edit"));
const EditFactory = lazy(() => import("routes/factories/Edit"));
const EditDeliverer = lazy(() => import("routes/deliverers/Edit"));
const EditRole = lazy(() => import("routes/roles/Edit"));
const EditUser = lazy(() => import("routes/users/Edit"));
const EditFactoryRequest = lazy(() => import("routes/factoryRequests/Edit"));
const EditTransport = lazy(() => import("routes/transportRequests/Edit"));
const EditOrder = lazy(() => import("routes/orders/Edit"));
const EditRetailOrder = lazy(() => import("routes/retailOrders/Edit"));
const EditAfterSaleRequest = lazy(() => import("routes/afterSaleRequests/Edit"));
const EditWidth = lazy(() => import("routes/catalog/widths/Edit"));
const EditUserToUser = lazy(() => import("routes/userToUsers/Edit"));
const EditUserToUserHierarchy = lazy(() => import("routes/userToUsers/EditHierarchy"));
const DetailsTracking = lazy(() => import("routes/tracking/Details"));

const ListOfCompanies = lazy(() => import("routes/catalog/companies/List"));
const ListOfConfigurations = lazy(() => import("routes/catalog/configurations/List"));
const ListOfDepths = lazy(() => import("routes/catalog/depths/List"));
const ListOfFurnitures = lazy(() => import("routes/catalog/furnitures/List"));
const ListOfMerchs = lazy(() => import("routes/catalog/merchs/List"));
const ListOfProducts = lazy(() => import("routes/catalog/products/List"));
const ListOfStores = lazy(() => import("routes/stores/List"));
const ListOfFactories = lazy(() => import("routes/factories/List"));
const ListOfDeliverers = lazy(() => import("routes/deliverers/List"));
const ListOfRoles = lazy(() => import("routes/roles/List"));
const ListOfUsers = lazy(() => import("routes/users/List"));
const ListOfFactoryRequests = lazy(() => import("routes/factoryRequests/List"));
const ListOfTransports = lazy(() => import("routes/transportRequests/List"));
const ListOfOrders = lazy(() => import("routes/orders/List"));
const ListOfRetailOrders = lazy(() => import("routes/retailOrders/List"));
const ListOfAfterSaleRequest = lazy(() => import("routes/afterSaleRequests/List"));
const ListOfWidths = lazy(() => import("routes/catalog/widths/List"));
const ListOfUserToUsers = lazy(() => import("routes/userToUsers/List"));
const ListOfTrackings = lazy(() => import("routes/tracking/List"));

const Logs = lazy(() => import("routes/logs/Logs"));
const RolePermissions = lazy(() => import("routes/permissions/Permissions"));
const Planning = lazy(() => import("routes/planning/Planning"));
const StocksV2 = lazy(() => import("routes/stocks/StocksV2"));
const Billing = lazy(() => import("routes/billing/Billing"));
const Activate = lazy(() => import("routes/tracking/Activate"));
const TrackingEndOfLife = lazy(() => import("routes/tracking/EndOfLife"));
const TrackingCreateOrUpdate = lazy(() => import("routes/tracking/CreateOrUpdate"));
const TransfertTracking = lazy(() => import("routes/tracking/Transfert"));

export interface IRoute {
  path: string;
  name: string | JSX.Element;
  exact: boolean;
  component: JSX.Element;
  children?: IRoute[];
  displayParamValue?: boolean;
}

export default function useRoutes() {
  const { t } = useTranslation(["common"]);

  const routes: IRoute[] = useMemo(
    () =>
      [
        {
          path: "/",
          name: <Home />,
          exact: true,
          component: (
            <Suspense fallback={<LoadingScreen />}>
              <HomeContent />
            </Suspense>
          ),
          children: [
            {
              path: "/account",
              name: t("breadcrumb.account"),
              exact: true,
              component: (
                <Suspense fallback={<LoadingScreen />}>
                  <Account />
                </Suspense>
              )
            },
            {
              path: "/permissions",
              name: t("breadcrumb.permissions"),
              exact: true,
              component: (
                <Suspense fallback={<LoadingScreen />}>
                  <RolePermissions />
                </Suspense>
              )
            },
            {
              path: "/roles",
              name: t("breadcrumb.roles"),
              exact: true,
              component: (
                <Suspense fallback={<LoadingScreen />}>
                  <ListOfRoles />
                </Suspense>
              ),
              children: [
                {
                  path: "/roles/create",
                  name: t("breadcrumb.roles.create"),
                  exact: true,
                  component: (
                    <Suspense fallback={<LoadingScreen />}>
                      <CreateRole />
                    </Suspense>
                  )
                },
                {
                  path: "/roles/:roleId",
                  name: t("breadcrumb.edit"),
                  exact: true,
                  component: (
                    <Suspense fallback={<LoadingScreen />}>
                      <EditRole />
                    </Suspense>
                  )
                }
              ]
            },
            {
              path: "/users",
              name: t("breadcrumb.users"),
              exact: true,
              component: (
                <Suspense fallback={<LoadingScreen />}>
                  <ListOfUsers />
                </Suspense>
              ),
              children: [
                {
                  path: "/users/create",
                  name: t("breadcrumb.users.create"),
                  exact: true,
                  component: (
                    <Suspense fallback={<LoadingScreen />}>
                      <CreateUser />
                    </Suspense>
                  )
                },
                {
                  path: "/users/:userId",
                  name: t("breadcrumb.edit"),
                  exact: true,
                  component: (
                    <Suspense fallback={<LoadingScreen />}>
                      <EditUser />
                    </Suspense>
                  )
                }
              ]
            },
            {
              path: "/retailOrders",
              name: t("breadcrumb.retailOrders"),
              exact: true,
              component: (
                <Suspense fallback={<LoadingScreen />}>
                  <ListOfRetailOrders />
                </Suspense>
              ),
              children: [
                {
                  path: "/retailOrders/create",
                  name: t("breadcrumb.retailOrders.create"),
                  exact: true,
                  component: (
                    <Suspense fallback={<LoadingScreen />}>
                      <CreateRetailOrder />
                    </Suspense>
                  )
                },
                {
                  path: "/retailOrders/:retailOrderId",
                  name: t("breadcrumb.edit"),
                  exact: true,
                  component: (
                    <Suspense fallback={<LoadingScreen />}>
                      <EditRetailOrder />
                    </Suspense>
                  )
                }
              ]
            },
            {
              path: "/orders",
              name: t("breadcrumb.orders"),
              exact: true,
              component: (
                <Suspense fallback={<LoadingScreen />}>
                  <ListOfOrders />
                </Suspense>
              ),
              children: [
                {
                  path: "/orders/create",
                  name: t("breadcrumb.orders.create"),
                  exact: true,
                  component: (
                    <Suspense fallback={<LoadingScreen />}>
                      <CreateOrder />
                    </Suspense>
                  )
                },
                {
                  path: "/orders/:orderId",
                  name: t("breadcrumb.edit"),
                  exact: true,
                  component: (
                    <Suspense fallback={<LoadingScreen />}>
                      <EditOrder />
                    </Suspense>
                  )
                }
              ]
            },
            {
              path: "/logs",
              name: t("breadcrumb.logs"),
              exact: true,
              component: (
                <Suspense fallback={<LoadingScreen />}>
                  <Logs />
                </Suspense>
              )
            },
            {
              path: "/catalog",
              name: t("breadcrumb.catalog"),
              exact: true,
              component: (
                <Suspense fallback={<LoadingScreen />}>
                  <Catalog />
                </Suspense>
              ),
              children: [
                {
                  path: "/catalog/companies",
                  name: t("breadcrumb.catalog.companies"),
                  exact: true,
                  component: (
                    <Suspense fallback={<LoadingScreen />}>
                      <ListOfCompanies />
                    </Suspense>
                  ),
                  children: [
                    {
                      path: "/catalog/companies/create",
                      name: t("breadcrumb.catalog.companies.create"),
                      exact: true,
                      component: (
                        <Suspense fallback={<LoadingScreen />}>
                          <CreateCompany />
                        </Suspense>
                      )
                    },
                    {
                      path: "/catalog/companies/:companyId",
                      name: t("breadcrumb.edit"),
                      exact: true,
                      component: (
                        <Suspense fallback={<LoadingScreen />}>
                          <EditCompany />
                        </Suspense>
                      )
                    }
                  ]
                },
                {
                  path: "/catalog/furnitures",
                  name: t("breadcrumb.catalog.furnitures"),
                  exact: true,
                  component: (
                    <Suspense fallback={<LoadingScreen />}>
                      <ListOfFurnitures />
                    </Suspense>
                  ),
                  children: [
                    {
                      path: "/catalog/furnitures/create",
                      name: t("breadcrumb.catalog.furnitures.create"),
                      exact: true,
                      component: (
                        <Suspense fallback={<LoadingScreen />}>
                          <CreateFurniture />
                        </Suspense>
                      )
                    },
                    {
                      path: "/catalog/furnitures/:furnitureId",
                      name: t("breadcrumb.edit"),
                      exact: true,
                      component: (
                        <Suspense fallback={<LoadingScreen />}>
                          <EditFurniture />
                        </Suspense>
                      )
                    }
                  ]
                },
                {
                  path: "/catalog/depths",
                  name: t("breadcrumb.catalog.depths"),
                  exact: true,
                  component: (
                    <Suspense fallback={<LoadingScreen />}>
                      <ListOfDepths />
                    </Suspense>
                  ),
                  children: [
                    {
                      path: "/catalog/depths/create",
                      name: t("breadcrumb.catalog.depths.create"),
                      exact: true,
                      component: (
                        <Suspense fallback={<LoadingScreen />}>
                          <CreateDepth />
                        </Suspense>
                      )
                    },
                    {
                      path: "/catalog/depths/:depthId",
                      name: t("breadcrumb.edit"),
                      exact: true,
                      component: (
                        <Suspense fallback={<LoadingScreen />}>
                          <EditDepth />
                        </Suspense>
                      )
                    }
                  ]
                },
                {
                  path: "/catalog/widths",
                  name: t("breadcrumb.catalog.widths"),
                  exact: true,
                  component: (
                    <Suspense fallback={<LoadingScreen />}>
                      <ListOfWidths />
                    </Suspense>
                  ),
                  children: [
                    {
                      path: "/catalog/widths/create",
                      name: t("breadcrumb.catalog.widths.create"),
                      exact: true,
                      component: (
                        <Suspense fallback={<LoadingScreen />}>
                          <CreateWidth />
                        </Suspense>
                      )
                    },
                    {
                      path: "/catalog/widths/:widthId",
                      name: t("breadcrumb.edit"),
                      exact: true,
                      component: (
                        <Suspense fallback={<LoadingScreen />}>
                          <EditWidth />
                        </Suspense>
                      )
                    }
                  ]
                },
                {
                  path: "/catalog/merchs",
                  name: t("breadcrumb.catalog.merchs"),
                  exact: true,
                  component: (
                    <Suspense fallback={<LoadingScreen />}>
                      <ListOfMerchs />
                    </Suspense>
                  ),
                  children: [
                    {
                      path: "/catalog/merchs/create",
                      name: t("breadcrumb.catalog.merchs.create"),
                      exact: true,
                      component: (
                        <Suspense fallback={<LoadingScreen />}>
                          <CreateMerch />
                        </Suspense>
                      )
                    },
                    {
                      path: "/catalog/merchs/:merchId",
                      name: t("breadcrumb.edit"),
                      exact: true,
                      component: (
                        <Suspense fallback={<LoadingScreen />}>
                          <EditMerch />
                        </Suspense>
                      )
                    }
                  ]
                },
                {
                  path: "/catalog/products",
                  name: t("breadcrumb.catalog.products"),
                  exact: true,
                  component: (
                    <Suspense fallback={<LoadingScreen />}>
                      <ListOfProducts />
                    </Suspense>
                  ),
                  children: [
                    {
                      path: "/catalog/products/create",
                      name: t("breadcrumb.catalog.products.create"),
                      exact: true,
                      component: (
                        <Suspense fallback={<LoadingScreen />}>
                          <CreateProduct />
                        </Suspense>
                      )
                    },
                    {
                      path: "/catalog/products/:productId",
                      name: t("breadcrumb.edit"),
                      exact: true,
                      component: (
                        <Suspense fallback={<LoadingScreen />}>
                          <EditProduct />
                        </Suspense>
                      )
                    }
                  ]
                },
                {
                  path: "/catalog/configurations",
                  name: t("breadcrumb.catalog.configurations"),
                  exact: true,
                  component: (
                    <Suspense fallback={<LoadingScreen />}>
                      <ListOfConfigurations />
                    </Suspense>
                  ),
                  children: [
                    {
                      path: "/catalog/configurations/create",
                      name: t("breadcrumb.catalog.configurations.create"),
                      exact: true,
                      component: (
                        <Suspense fallback={<LoadingScreen />}>
                          <CreateConfiguration />
                        </Suspense>
                      )
                    },
                    {
                      path: "/catalog/configurations/:configurationId",
                      name: t("breadcrumb.edit"),
                      exact: true,
                      component: (
                        <Suspense fallback={<LoadingScreen />}>
                          <EditConfiguration />
                        </Suspense>
                      )
                    }
                  ]
                }
              ]
            },
            {
              path: "/stores",
              name: t("breadcrumb.stores"),
              exact: true,
              component: (
                <Suspense fallback={<LoadingScreen />}>
                  <ListOfStores />
                </Suspense>
              ),
              children: [
                {
                  path: "/stores/create",
                  name: t("breadcrumb.stores.create"),
                  exact: true,
                  component: (
                    <Suspense fallback={<LoadingScreen />}>
                      <CreateStore />
                    </Suspense>
                  )
                },
                {
                  path: "/stores/:storeId",
                  name: t("breadcrumb.edit"),
                  exact: true,
                  component: (
                    <Suspense fallback={<LoadingScreen />}>
                      <EditStore />
                    </Suspense>
                  )
                }
              ]
            },
            {
              path: "/stocks",
              name: t("breadcrumb.stocks"),
              exact: true,
              component: (
                <Suspense fallback={<LoadingScreen />}>
                  <StocksV2 />
                </Suspense>
              ),
              children: []
            },
            {
              path: "/deliverers",
              name: t("breadcrumb.deliverers"),
              exact: true,
              component: (
                <Suspense fallback={<LoadingScreen />}>
                  <ListOfDeliverers />
                </Suspense>
              ),
              children: [
                {
                  path: "/deliverers/create",
                  name: t("breadcrumb.deliverers.create"),
                  exact: true,
                  component: (
                    <Suspense fallback={<LoadingScreen />}>
                      <CreateDeliverer />
                    </Suspense>
                  )
                },
                {
                  path: "/deliverers/:delivererId",
                  name: t("breadcrumb.edit"),
                  exact: true,
                  component: (
                    <Suspense fallback={<LoadingScreen />}>
                      <EditDeliverer />
                    </Suspense>
                  )
                }
              ]
            },
            {
              path: "/factories",
              name: t("breadcrumb.factories"),
              exact: true,
              component: (
                <Suspense fallback={<LoadingScreen />}>
                  <ListOfFactories />
                </Suspense>
              ),
              children: [
                {
                  path: "/factories/create",
                  name: t("breadcrumb.factories.create"),
                  exact: true,
                  component: (
                    <Suspense fallback={<LoadingScreen />}>
                      <CreateFactory />
                    </Suspense>
                  )
                },
                {
                  path: "/factories/:factoryId",
                  name: t("breadcrumb.edit"),
                  exact: true,
                  component: (
                    <Suspense fallback={<LoadingScreen />}>
                      <EditFactory />
                    </Suspense>
                  )
                }
              ]
            },
            {
              path: "/planning",
              name: t("breadcrumb.planning"),
              exact: true,
              component: (
                <Suspense fallback={<LoadingScreen />}>
                  <Planning />
                </Suspense>
              )
            },
            {
              path: "/factoryRequests",
              name: t("breadcrumb.factoryRequests"),
              exact: true,
              component: (
                <Suspense fallback={<LoadingScreen />}>
                  <ListOfFactoryRequests />
                </Suspense>
              ),
              children: [
                {
                  path: "/factoryRequests/:factoryRequestId",
                  name: t("breadcrumb.edit"),
                  exact: true,
                  component: (
                    <Suspense fallback={<LoadingScreen />}>
                      <EditFactoryRequest />
                    </Suspense>
                  )
                }
              ]
            },
            {
              path: "/transportRequests",
              name: t("breadcrumb.transportRequests"),
              exact: true,
              component: (
                <Suspense fallback={<LoadingScreen />}>
                  <ListOfTransports />
                </Suspense>
              ),
              children: [
                {
                  path: "/transportRequests/:transportRequestId",
                  name: t("breadcrumb.edit"),
                  exact: true,
                  component: (
                    <Suspense fallback={<LoadingScreen />}>
                      <EditTransport />
                    </Suspense>
                  )
                }
              ]
            },
            {
              path: "/afterSaleRequests",
              name: t("breadcrumb.afterSaleRequests"),
              exact: true,
              component: (
                <Suspense fallback={<LoadingScreen />}>
                  <ListOfAfterSaleRequest />
                </Suspense>
              ),
              children: [
                {
                  path: "/afterSaleRequests/create",
                  name: t("breadcrumb.afterSaleRequests.create"),
                  exact: true,
                  component: (
                    <Suspense fallback={<LoadingScreen />}>
                      <CreateAfterSaleRequest />
                    </Suspense>
                  )
                },
                {
                  path: "/afterSaleRequests/:afterSaleRequestId",
                  name: t("breadcrumb.edit"),
                  exact: true,
                  component: (
                    <Suspense fallback={<LoadingScreen />}>
                      <EditAfterSaleRequest />
                    </Suspense>
                  )
                }
              ]
            },
            {
              path: "/billing",
              name: t("breadcrumb.billing"),
              exact: true,
              component: (
                <Suspense fallback={<LoadingScreen />}>
                  <Billing />
                </Suspense>
              )
            },
            {
              path: "/userToUsers",
              name: t("breadcrumb.userToUsers"),
              exact: true,
              component: (
                <Suspense fallback={<LoadingScreen />}>
                  <ListOfUserToUsers />
                </Suspense>
              ),
              children: [
                {
                  path: "/userToUsers/create",
                  name: t("breadcrumb.create"),
                  exact: true,
                  component: (
                    <Suspense fallback={<LoadingScreen />}>
                      <CreateUserToUser />
                    </Suspense>
                  )
                },
                {
                  path: "/userToUsers/:userToUserId",
                  name: t("breadcrumb.edit"),
                  exact: true,
                  component: (
                    <Suspense fallback={<LoadingScreen />}>
                      <EditUserToUser />
                    </Suspense>
                  )
                },
                {
                  path: "/userToUsers/hierarchy/:userToUserId",
                  name: t("breadcrumb.edit"),
                  exact: true,
                  component: (
                    <Suspense fallback={<LoadingScreen />}>
                      <EditUserToUserHierarchy />
                    </Suspense>
                  )
                }
              ]
            },
            {
              path: "/tracking",
              name: t("breadcrumb.trackings"),
              exact: true,
              component: (
                <Suspense fallback={<LoadingScreen />}>
                  <ListOfTrackings />
                </Suspense>
              ),
              children: [
                {
                  path: "/tracking/activate",
                  name: t("breadcrumb.trackings.activate"),
                  exact: true,
                  component: (
                    <Suspense fallback={<LoadingScreen />}>
                      <Activate />
                    </Suspense>
                  )
                },
                {
                  path: "/tracking/endoflife",
                  name: t("breadcrumb.trackings.endOfLife"),
                  exact: true,
                  component: (
                    <Suspense fallback={<LoadingScreen />}>
                      <TrackingEndOfLife />
                    </Suspense>
                  )
                },
                {
                  path: "/tracking/createorupdate",
                  name: t("breadcrumb.trackings.createOrUpdate"),
                  exact: true,
                  component: (
                    <Suspense fallback={<LoadingScreen />}>
                      <TrackingCreateOrUpdate />
                    </Suspense>
                  )
                },
                {
                  path: "/tracking/transfert",
                  name: t("breadcrumb.trackings.transfert"),
                  exact: true,
                  component: (
                    <Suspense fallback={<LoadingScreen />}>
                      <TransfertTracking />
                    </Suspense>
                  )
                },
                {
                  path: "/tracking/:trackingId",
                  name: t("breadcrumb.trackings.details"),
                  exact: true,
                  component: (
                    <Suspense fallback={<LoadingScreen />}>
                      <DetailsTracking />
                    </Suspense>
                  )
                }
              ]
            }
          ]
        }
      ] as IRoute[],
    [t]
  );

  const getChildrenRoutes = useCallback((route: IRoute) => {
    let result: IRoute[] = [];
    if (route.children?.length) {
      route.children.forEach((routeChild) => {
        result.push(routeChild);
        let childrenRoutes = getChildrenRoutes(routeChild);
        result = [...result, ...childrenRoutes];
      });
    }
    return result;
  }, []);

  const routesFlattened: IRoute[] = useMemo(() => {
    let result: IRoute[] = [];
    routes.forEach((route) => {
      result.push(route);
      let childrenRoutes = getChildrenRoutes(route);
      result = [...result, ...childrenRoutes];
    });
    return result;
  }, [routes, getChildrenRoutes]);

  return { routes, routesFlattened };
}
