import { DialogProps } from "@mui/material";
import React, { FC } from "react";

export interface IModalProps {
  id?: string;
  title?: string;
  content?: any;
  dialogProps?: DialogProps | any;
}

export interface IModal {
  component: FC<any>;
  props: IModalProps;
}

export const ModalContext = React.createContext({
  addModal: (component: IModal) => "modalId" as string,
  dismissModal: (id: string | undefined) => {},
  dismissAll: () => {},
});
