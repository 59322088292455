// /// <reference types="@welldone-software/why-did-you-render" />
import "./wdyr";
import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import theme from "./theme";
import { ThemeProvider } from "@mui/material/styles";
import store from "redux/store";
import { Provider } from "react-redux";
import { HashRouter } from "react-router-dom";
import "i18n"; // import transalation initialization
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ModalProvider from "providers/ModalProvider";
import "react-data-grid/dist/react-data-grid.css";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers";
import moment from "moment";
import "moment/locale/fr";
// import ConfigProvider from "providers/ConfigProvider";

moment.locale("fr");
ReactDOM.render(
  <React.StrictMode>
    {/* <ConfigProvider> */}
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <ModalProvider>
            <HashRouter>
              <App />
            </HashRouter>
          </ModalProvider>
        </ThemeProvider>
      </Provider>
      <ToastContainer position="bottom-right" />
    </LocalizationProvider>
    {/* </ConfigProvider> */}
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
