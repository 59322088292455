import AbpAppService from "api/AbpAppService";
import axios, { AxiosResponse } from "axios";
import { IAbpResponseGet } from "types/IApi";
import { IUser } from "types/IUser";
import { IFactory } from "./factory";
import { IOrder } from "./order";
import * as ApiSettings from "./apiSettings";
import { IFile, ITempFile } from "helpers/files";
import { IStatistics } from "routes/app/charts/Orders";
import { getResponseExceptionMessage } from "./apiSettings";

export interface IFactoryRequest {
  id: string;
  reference: string;
  status: FactoryRequestStatusEnum;
  statusLines: IFactoryRequestStatusLine[];
  loadingDate: string;
  creationTime: string;
  order: IOrder;
  factory: IFactory;
  factoryId: string;
  files?: IFactoryRequestFile[];
  tempFiles?: ITempFile[];
}

export interface IFactoryRequestFile {
  afterSaleRequestId: string;
  file: IFile;
  fileId: string;
}

export interface IFactoryRequestStatusLine {
  id: string;
  creationTime: string;
  status: FactoryRequestStatusEnum;
  creatorUser: IUser;
  comment?: string;
}

export enum FactoryRequestStatusEnum {
  Draft = 1, // En cours
  Pending, // En cours de livraison
  Done, // Terminée
  Canceled, // Annulée
}

export const FactoryRequestStatusLabels = {
  [FactoryRequestStatusEnum.Draft]: "En attente de prise en charge",
  [FactoryRequestStatusEnum.Pending]: "Pris en charge",
  [FactoryRequestStatusEnum.Done]: "Terminée",
  [FactoryRequestStatusEnum.Canceled]: "Annulée",
};

export const FactoryRequestStatusColors = {
  [FactoryRequestStatusEnum.Draft]: "#A9ADAA",
  [FactoryRequestStatusEnum.Pending]: "#58B8DC",
  [FactoryRequestStatusEnum.Done]: "#4EE683",
  [FactoryRequestStatusEnum.Canceled]: "#DC5858",
};
export interface ICreateFactoryRequest
  extends Omit<IFactoryRequest, "id" | "order" | "factory"> {
  orderId: string;
}

export const defaultEntity: IFactoryRequest = {} as IFactoryRequest;

export const defaultCreateEntity: ICreateFactoryRequest = {
  loadingDate: "",
  status: FactoryRequestStatusEnum.Draft,
  statusLines: [],
  reference: "",
  creationTime: "",
  orderId: "",
  factoryId: "",
};

export const service = new AbpAppService<IFactoryRequest, IFactoryRequest>(
  "FactoryRequest"
);

export const validateAsync = (
  factoryRequest: IFactoryRequest
): Promise<AxiosResponse<IAbpResponseGet<IFactoryRequest>>> =>
  axios.post(
    `${ApiSettings.apiBaseUrl}${service.servicePath}${service.serviceName}/Validate`,
    factoryRequest,
    {
      headers: {
        ...ApiSettings.getDefaultHeaders(),
      },
    }
  );

export const refuseAsync = (
  factoryRequest: IFactoryRequest
): Promise<AxiosResponse<IAbpResponseGet<IFactoryRequest>>> =>
  axios.post(
    `${ApiSettings.apiBaseUrl}${service.servicePath}${service.serviceName}/Refuse`,
    factoryRequest,
    {
      headers: {
        ...ApiSettings.getDefaultHeaders(),
      },
    }
  );

export const addCommentAsync = (params: {
  factoryRequestId: string;
  comment: string;
}): Promise<AxiosResponse<IAbpResponseGet<IFactoryRequestStatusLine>>> =>
  axios.post(
    `${ApiSettings.apiBaseUrl}${service.servicePath}${service.serviceName}/AddComment`,
    params,
    {
      headers: {
        ...ApiSettings.getDefaultHeaders(),
      },
    }
  );

export const getStatistics = async (params: {}): Promise<
  IAbpResponseGet<IStatistics<FactoryRequestStatusEnum>>
> => {
  let result = {} as IAbpResponseGet<IStatistics<FactoryRequestStatusEnum>>;
  try {
    result = await service.invoke("get", "GetStatistics", params);
  } catch (error) {
    console.log(getResponseExceptionMessage(error));
  }
  return result;
};
