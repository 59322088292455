import { createAsyncThunk } from "@reduxjs/toolkit";
import { register } from "api/account";
import { getResponseExceptionMessage } from "api/apiSettings";
import { IRegistrationInput } from "types/IRegistration";

export const registerAsync = createAsyncThunk(
  "registration/register",
  async (input: IRegistrationInput, thunkApi) => {
    try {
      const apiResult = await register(input);
      return apiResult;
    } catch (error) {
      const errorMsg = getResponseExceptionMessage(error);
      throw errorMsg;
    }
  }
);
