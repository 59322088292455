import axios from "axios";
import * as apiSettings from "api/apiSettings";
import { deleteCookie, setCookie } from "helpers/cookie";

export const accessTokenName = "accessToken";
export const encryptedAccessTokenName = "encryptedAccessToken";

export const tokenAuth = async (
  login: string,
  password: string
): Promise<any> => {
  const apiResult = await axios.post(
    `${apiSettings.apiBaseUrl}/api/TokenAuth/Authenticate`,
    {
      userNameOrEmailAddress: login,
      password: password,
      rememberMe: true,
    },
    {
      headers: {
        ...apiSettings.getDefaultHeaders(),
      },
    }
  );
  setCookie(accessTokenName, apiResult.data.result.accessToken, 30);
  setCookie(
    encryptedAccessTokenName,
    apiResult.data.result.encryptedAccessToken,
    30
  );
  return apiResult;
};

export const logout = () => {
  try {
    deleteCookie("accessToken");
    deleteCookie("encryptedAccessToken");
  } catch (error) {}
};
