import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IFilter } from "components/MuiReactTable";
import { createSelector } from "reselect";

export interface IFormState {
  forms: IForm[];
}

export const initialState: IFormState = {
  forms: [],
};

export interface IForm {
  id: string;
  filters: IFilter[];
}

const formsSlice = createSlice({
  name: "form",
  initialState: initialState, // for TypeScript check
  reducers: {
    reset: (state, action: PayloadAction<string>) => {
      if (action.payload && action.payload !== "") {
        const index = state.forms.findIndex((o) => o.id === action.payload);
        if (index !== -1) {
          state.forms.splice(index, 1);
        }
      }
      return initialState;
    },
    setForm: (state, action) => {
      const index = state.forms.findIndex((o) => o.id === action?.payload?.id);
      if (index !== -1) {
        state.forms[index] = action.payload;
      } else {
        state.forms.push(action.payload);
      }
    },
  },
});

export const selectFormById = createSelector(
  (forms: IForm[]) => forms,
  (forms: IForm[], id: string) => id,
  (forms, id) => {
    const index = forms.findIndex((o) => o.id === id);
    if (index !== -1) {
      return forms[index];
    } else {
      return null;
    }
  }
);

export const { reset, setForm } = formsSlice.actions;
export default formsSlice.reducer;
