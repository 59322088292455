import { configureStore } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import AuthReducer from "redux/reducers/auth";
import RegistrationReducer from "redux/reducers/registration";
import FormsReducer from "redux/reducers/forms";
import OrderReducer from "redux/reducers/order";
import DetailOrderReducer from "redux/reducers/retailOrder";
import FactoryRequestReducer from "redux/reducers/factoryRequest";
import TransportRequestReducer from "redux/reducers/transportRequest";
import AfterSaleRequestReducer from "redux/reducers/afterSaleRequest";

const store = configureStore({
  reducer: {
    auth: AuthReducer,
    forms: FormsReducer,
    registration: RegistrationReducer,
    order: OrderReducer,
    retailOrder: DetailOrderReducer,
    factoryRequest: FactoryRequestReducer,
    transportRequest: TransportRequestReducer,
    afterSaleRequest: AfterSaleRequestReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
export type AppSubscribe = typeof store.subscribe;

export const useAppDispatch = () => useDispatch<AppDispatch>(); // Export a hook that can be reused to resolve types
export default store;
