export function setCookie(name: string, value: any, days: number) {
  var d = new Date();
  d.setTime(d.getTime() + days * 24 * 60 * 60 * 1000);
  var expires = "expires=" + d.toUTCString();
  document.cookie = name + "=" + value + ";" + expires + ";path=/";
}

export function getCookie(name: string) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) {
    return parts?.pop()?.split(";").shift();
  }
}

export function deleteCookie(name: string, path: string = "/") {
  document.cookie = name + "=; Max-Age=-99999999;path=" + path + ";";
}
