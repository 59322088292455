import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { service } from "api/factoryRequest";
import { IFactoryRequest, IFactoryRequestStatusLine } from "api/factoryRequest";
import { AppDispatch, RootState } from "redux/store";
import {
  validateAsync as validateAsyncApi,
  refuseAsync as refuseAsyncApi,
  addCommentAsync as addCommentAsyncApi,
} from "api/factoryRequest";

export interface IFactoryRequestState {
  busy?: boolean;
  error?: string;
  entity?: IFactoryRequest;
}

export const initialState: IFactoryRequestState = {
  busy: false,
  error: "",
  entity: undefined,
};

const getFactoryRequestStateFromFactoryRequest = (
  factoryRequest: IFactoryRequest
): IFactoryRequestState => {
  return {
    ...initialState,
    entity: factoryRequest,
  };
};

export const getAsync = createAsyncThunk<
  IFactoryRequestState,
  string,
  {
    dispatch: AppDispatch;
    state: RootState;
  }
>("factoryRequest/getAsync", async (input, thunkApi) => {
  const factoryRequest: IFactoryRequest = (await service.get({ id: input }))
    .result;
  const factoryRequestState =
    getFactoryRequestStateFromFactoryRequest(factoryRequest);
  return factoryRequestState;
});

export const updateAsync = createAsyncThunk<
  IFactoryRequestState,
  IFactoryRequest,
  {
    dispatch: AppDispatch;
    state: RootState;
  }
>("factoryRequest/updateAsync", async (input, thunkApi) => {
  const factoryRequest: IFactoryRequest = (
    await service.update({
      ...input,
    })
  ).result;
  const factoryRequestState =
    getFactoryRequestStateFromFactoryRequest(factoryRequest);
  return factoryRequestState;
});

export const validateAsync = createAsyncThunk<
  IFactoryRequestState,
  IFactoryRequest,
  {
    dispatch: AppDispatch;
    state: RootState;
  }
>("factoryRequest/validateAsync", async (input, thunkApi) => {
  const factoryRequest: IFactoryRequest = (await validateAsyncApi(input)).data
    .result;
  const factoryRequestState =
    getFactoryRequestStateFromFactoryRequest(factoryRequest);
  return factoryRequestState;
});

export const refuseAsync = createAsyncThunk<
  IFactoryRequestState,
  IFactoryRequest,
  {
    dispatch: AppDispatch;
    state: RootState;
  }
>("factoryRequest/refuseAsync", async (input, thunkApi) => {
  const factoryRequest: IFactoryRequest = (await refuseAsyncApi(input)).data
    .result;
  const factoryRequestState =
    getFactoryRequestStateFromFactoryRequest(factoryRequest);
  return factoryRequestState;
});

export const addCommentAsync = createAsyncThunk<
  IFactoryRequestStatusLine,
  { factoryRequestId: string; comment: string },
  {
    dispatch: AppDispatch;
    state: RootState;
  }
>("factoryRequest/addCommentAsync", async (input, thunkApi) => {
  const result: IFactoryRequestStatusLine = (await addCommentAsyncApi(input))
    .data.result;
  return result;
});

const factoryRequestSlice = createSlice({
  name: "factoryRequest",
  initialState: initialState, // for TypeScript check
  reducers: {
    reset: (state) => {
      let newState = { ...initialState };
      return newState;
    },
    updateState: (
      state,
      action: PayloadAction<Partial<IFactoryRequestState>>
    ) => {
      return { ...state, ...action.payload };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAsync.pending, (state, action) => {
      state.busy = true;
      state.error = undefined;
    });
    builder.addCase(getAsync.rejected, (state, action) => {
      state.busy = false;
      state.error = action.error.message;
    });
    builder.addCase(getAsync.fulfilled, (state, action) => {
      return {
        ...action.payload,
        busy: false,
        error: undefined,
      };
    });
    builder.addCase(updateAsync.pending, (state, action) => {
      state.busy = true;
      state.error = undefined;
    });
    builder.addCase(updateAsync.rejected, (state, action) => {
      state.busy = false;
      state.error = action.error.message;
    });
    builder.addCase(updateAsync.fulfilled, (state, action) => {
      return {
        ...action.payload,
        busy: false,
        error: undefined,
      };
    });
    builder.addCase(addCommentAsync.pending, (state, action) => {
      state.busy = true;
      state.error = undefined;
    });
    builder.addCase(addCommentAsync.rejected, (state, action) => {
      state.busy = false;
      state.error = action.error.message;
    });
    builder.addCase(addCommentAsync.fulfilled, (state, action) => {
      state.entity?.statusLines?.unshift(action.payload);
      state.busy = false;
      state.error = undefined;
    });
    builder.addCase(validateAsync.pending, (state, action) => {
      state.busy = true;
      state.error = undefined;
    });
    builder.addCase(validateAsync.rejected, (state, action) => {
      state.busy = false;
      state.error = action.error.message;
    });
    builder.addCase(validateAsync.fulfilled, (state, action) => {
      return {
        ...action.payload,
        busy: false,
        error: undefined,
      };
    });
    builder.addCase(refuseAsync.pending, (state, action) => {
      state.busy = true;
      state.error = undefined;
    });
    builder.addCase(refuseAsync.rejected, (state, action) => {
      state.busy = false;
      state.error = action.error.message;
    });
    builder.addCase(refuseAsync.fulfilled, (state, action) => {
      return {
        ...action.payload,
        busy: false,
        error: undefined,
      };
    });
  },
});

export const { reset, updateState } = factoryRequestSlice.actions;
export default factoryRequestSlice.reducer;
