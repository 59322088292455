import { IUserSession } from "redux/reducers/auth";

export function hasRole(
  user: IUserSession,
  roleName: string,
  allowAdmin: boolean = true
): boolean {
  let result = false;
  user.roles.forEach((roleNameItem) => {
    if (roleNameItem.toUpperCase() === roleName.toUpperCase()) {
      result = true;
    }
    if (allowAdmin === true && roleNameItem.toUpperCase() === "ADMIN") {
      result = true;
    }
  });
  return result;
}

export function hasRoles(
  user: IUserSession,
  roleNames: string[],
  allowAdmin?: boolean
): boolean {
  roleNames.forEach((roleName) => {
    if (hasRole(user, roleName) === false) {
      return false;
    }
  });
  return true;
}

export function hasOneOfRoles(
  user: IUserSession,
  roleNames: string[],
  allowAdmin?: boolean
): boolean {
  return roleNames.some((roleName) => {
    return hasRole(user, roleName) === true;
  });
}

export function isSuperAdmin(user: IUserSession): boolean {
  return hasRole(user, "Admin");
}

export function isAdmin(user: IUserSession): boolean {
  return hasOneOfRoles(user, ["Admin", "AdminPLV", "AdminInstore"]);
}

export function isCofinancer(user: IUserSession): boolean {
  return hasRole(user, "Cofinancer");
}

export function hasPermission(
  user: IUserSession,
  permissionName: string
): boolean {
  return user.permissions?.indexOf(permissionName) !== -1;
}

export function hasPermissions(
  user: IUserSession,
  permissionNames: string[]
): boolean {
  permissionNames.forEach((permissionName) => {
    if (hasPermission(user, permissionName) === false) {
      return false;
    }
  });
  return true;
}

export function hasOneOfPermissions(
  user: IUserSession,
  permissionNames: string[]
): boolean {
  return permissionNames.some((permissionName) => {
    return hasPermission(user, permissionName) === true;
  });
}

export function hasPermissionOrRole(
  user: IUserSession,
  permissionName: string,
  roleName: string
): boolean {
  return hasPermission(user, permissionName) || hasRole(user, roleName);
}
