import AbpAppService from "api/AbpAppService";
import { IStatistics } from "routes/app/charts/Orders";
import { IAbpResponseGet } from "types/IApi";
import { IUser } from "types/IUser";
import { getResponseExceptionMessage } from "./apiSettings";
import { IProduct } from "./product";
import { IStoreDto } from "./store";

export interface IRetailOrder {
  id: string;
  reference: string;
  status: RetailOrderStatusEnum;
  address: string;
  city: string;
  postalCode: string;
  loadingDate: string;
  deliveryDate: string;
  statusLines: IRetailOrderStatusLine[];
  creationTime: string;
  products: IProduct[];
  store: IStoreDto;
  storeLabel: string;
  storeReference: string;
  contactEmail: string;
  contactName: string;
  contactPhoneNumber: string;
  signatureName?: string;
  signature?: string;
  creatorUser?: IUser;
  orderFormId?: string;
  orderFormSignedId?: string;
  deliveryFormId?: string;
  markupFileId?: string;
  factoryRequestId?: string;
  orderLines?: IOrderLine[];
  extraPreparation?: number;
  extraShipping?: number;
}

export interface IOrderLine {
  product: IProduct;
  quantity: number;
  total: number;
}

export interface IRetailOrderStatusLine {
  id: string;
  creationTime: string;
  status: RetailOrderStatusEnum;
  creatorUser: IUser;
  comment?: string;
}

export enum RetailOrderStatusEnum {
  Draft = 1, // En cours
  Signature, // En attente de signature
  Delivering, // En cours de livraison
  Done, // Terminée
  Canceled, // Annulée
  Shipping,
}

export const RetailOrderStatusLabels = {
  [RetailOrderStatusEnum.Draft]: "En cours",
  [RetailOrderStatusEnum.Signature]: "En attente de signature",
  [RetailOrderStatusEnum.Delivering]: "En cours de livraison",
  [RetailOrderStatusEnum.Done]: "Terminée",
  [RetailOrderStatusEnum.Canceled]: "Annulée",
  [RetailOrderStatusEnum.Shipping]: "En attente de frais de traitement",
};

export const RetailOrderStatusColors = {
  [RetailOrderStatusEnum.Draft]: "#A9ADAA",
  [RetailOrderStatusEnum.Signature]: "#A9ADAA",
  [RetailOrderStatusEnum.Delivering]: "#58B8DC",
  [RetailOrderStatusEnum.Done]: "#4EE683",
  [RetailOrderStatusEnum.Canceled]: "#DC5858",
  [RetailOrderStatusEnum.Shipping]: "#A9ADAA",
};
export interface ICreateRetailOrder extends Omit<IRetailOrder, "id"> {}

// export const defaultEntity: ICreateOrder = {
// };

export const service = new AbpAppService<IRetailOrder, IRetailOrder>(
  "RetailOrder"
);

export const getStatistics = async (params: {}): Promise<
  IAbpResponseGet<IStatistics<RetailOrderStatusEnum>>
> => {
  let result = {} as IAbpResponseGet<IStatistics<RetailOrderStatusEnum>>;
  try {
    result = await service.invoke("get", "GetStatistics", params);
  } catch (error) {
    console.log(getResponseExceptionMessage(error));
  }
  return result;
};
