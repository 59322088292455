import { Backdrop, CircularProgress, Theme } from "@mui/material";
import { makeStyles, createStyles } from "@mui/styles";
import React from "react";

export default function LoadingScreen() {
  const classes = useStyles();

  return (
    <Backdrop className={classes.backdrop} open={true}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: 99999,
      color: "#fff",
    },
  })
);
