import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

const resources = {
  en: {
    common: require("locales/en/common.json"),
    "components/muireacttable": require("locales/en/components/muireacttable.json"),
  },
  fr: {
    common: require("locales/fr/common.json"),
    "components/muireacttable": require("locales/fr/components/muireacttable.json"),
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .init({
    resources,
    // lng: "fr",
    fallbackLng: "fr",
    debug: false,

    keySeparator: false, // we do not use keys in form messages.welcome
    nsSeparator: "|",

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    react: {
      bindI18nStore: "added removed",
    },

    detection: {
      order: [
        "cookie",
        // "localStorage",
        "navigator",
        "querystring",
        "htmlTag",
        "path",
        "subdomain",
      ],

      lookupQuerystring: "lng",
      lookupCookie: "i18next",
      lookupLocalStorage: "i18nextLng",
      lookupFromPathIndex: 0,
      lookupFromSubdomainIndex: 0,

      caches: ["cookie"],
    },
  });

export default i18n;
