import React from "react";
import {
  Link,
  Typography,
  Breadcrumbs as MaterialBreadcrumbs,
} from "@mui/material";
import { IRoute } from "hooks/useRoutes";

const Breadcrumbs = ({ crumbs }: { crumbs: IRoute[] }) => {
  if (crumbs?.length < 1) {
    return null;
  }
  return (
    <MaterialBreadcrumbs
      aria-label="breadcrumb"
      separator={<Typography color="primary.contrastText">/</Typography>}
    >
      {/* Link back to any previous steps of the breadcrumb. */}
      {crumbs.map(({ name, path }, key: number) =>
        key + 1 === crumbs.length ? (
          <Typography key={key} color="primary.contrastText">
            {name}
          </Typography>
        ) : (
          <Link
            color="primary.contrastText"
            underline="hover"
            key={key}
            href={`/#${path}`}
          >
            {name}
          </Link>
        )
      )}
    </MaterialBreadcrumbs>
  );
};

export default Breadcrumbs;
