import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Alert, Button, TextField, Theme, Typography } from "@mui/material";
import { makeStyles, createStyles } from "@mui/styles";
import backgroundImage from "images/login_background.jpg";
import logo from "images/logo.png";
import { RootState, useAppDispatch } from "redux/store";
import { useSelector } from "react-redux";
import { loginAsync } from "redux/actions/auth";
import { ILoadingType } from "types/ILoadingType";
import { useLocation, useNavigate } from "react-router-dom";

const Login = () => {
  const appDispatch = useAppDispatch();
  const { loadStatus, error } = useSelector((state: RootState) => state.auth);
  const [localState, setLocalState] = useState({ username: "", password: "" });
  let navigate = useNavigate();
  const classes = useStyles();

  const isLoading = useMemo(
    () => loadStatus === ILoadingType.loading,
    [loadStatus]
  );

  const hasFailed = useMemo(
    () => loadStatus === ILoadingType.failed,
    [loadStatus]
  );

  const handleLink = useCallback(
    (path: string) => {
      navigate(path);
    },
    [navigate]
  );

  const onSubmit = useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      appDispatch(
        loginAsync({
          username: localState.username,
          password: localState.password,
        })
      );
    },
    [appDispatch, localState.password, localState.username]
  );

  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  }
  const query = useQuery();
  const redirect = query.get("redirect");

  useEffect(() => {
    if (loadStatus === ILoadingType.succeeded) {
      navigate(redirect || "/");
    }
  }, [navigate, loadStatus]);

  return (
    <div className={classes.container}>
      <div className={classes.containerBackground}></div>
      <div className={classes.containerBackgroundImage}></div>
      <div className={classes.contentContainer}>
        <img src={logo} className={classes.logo} alt="" />
        <form onSubmit={(e) => onSubmit(e)} className={classes.formContainer}>
          <Typography
            variant="h4"
            component="h2"
            gutterBottom
            textAlign="center"
          >
            Connexion
          </Typography>
          <TextField
            fullWidth
            id="login"
            label="Identifiant"
            margin="normal"
            name="login"
            // inputProps={{
            //   autoComplete: "off",
            //   form: {
            //     autocomplete: "off",
            //   },
            // }}
            onChange={(e) =>
              setLocalState((old) => ({ ...old, username: e.target.value }))
            }
            required
            value={localState.username}
            variant="outlined"
          />
          <TextField
            fullWidth
            id="password"
            label="Mot de passe"
            margin="normal"
            name="password"
            inputProps={{
              autoComplete: "new-password",
            }}
            onChange={(e) =>
              setLocalState((old) => ({ ...old, password: e.target.value }))
            }
            required
            type="password"
            value={localState.password}
            variant="outlined"
          />

          <div style={{ textAlign: "right" }}>
            <Button
              onClick={() => {
                handleLink("/forgotPassword");
              }}
            >
              Mot de passe oublié ?
            </Button>
          </div>
          {hasFailed && <Alert severity="error">{error}</Alert>}
          {isLoading && <Alert severity="info">Connexion en cours...</Alert>}
          <div className={classes.submitButtonContainer}>
            <Button
              color="primary"
              disabled={isLoading}
              fullWidth
              size="large"
              variant="contained"
              type="submit"
            >
              Valider
            </Button>
          </div>
          <div className={classes.submitButtonContainer}>
            <Button
              color="primary"
              disabled={isLoading}
              fullWidth
              onClick={() => {
                handleLink("/registration");
              }}
              type="button"
              size="large"
              variant="contained"
            >
              Inscription
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      height: "100vh",
      width: "100vw",
      display: "flex",
    },
    containerBackground: {
      position: "fixed",
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      background: theme.palette.secondary.main,
    },
    containerBackgroundImage: {
      backgroundImage: `url(${backgroundImage})`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      position: "fixed",
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
    },
    contentContainer: {
      position: "relative",
      margin: "0 auto", // auto auto to center both vertically / horizontally
      textAlign: "center",
      padding: 10,
    },
    logo: {
      margin: 10,
      marginBottom: 50,
      height: 50,
    },
    formContainer: {
      paddingTop: 40,
      paddingBottom: 40,
      paddingLeft: 30,
      paddingRight: 30,
      backgroundColor: "white",
      borderRadius: "10px",
      boxShadow: "0 0 10px #000",
      borderColor: theme.palette.primary.main,
      border: 2,
      borderStyle: "solid",
      maxWidth: 300,
    },
    submitButtonContainer: {
      marginTop: 20,
    },
  })
);
export default Login;
