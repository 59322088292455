import AbpAppService from "api/AbpAppService";
import axios, { AxiosResponse } from "axios";
import { IFile, ITempFile } from "helpers/files";
import { IStatistics } from "routes/app/charts/Orders";
import { IAbpResponseGet } from "types/IApi";
import { IUser } from "types/IUser";
import * as ApiSettings from "./apiSettings";
import { IOrder } from "./order";
import { IRetailOrder } from "./retailOrder";

export interface IAfterSaleRequest {
  id: string;
  reference: string;
  status: AfterSaleRequestStatusEnum;
  statusLines: IAfterSaleRequestStatusLine[];
  creationTime: string;
  creatorUser: IUser;
  orderId?: string;
  retailOrderId?: string;
  order?: IOrder;
  retailOrder?: IRetailOrder;
  files?: IAfterSaleRequestFile[];
  tempFiles?: ITempFile[];
  reason?: string;
}

export interface IAfterSaleRequestFile {
  afterSaleRequestId: string;
  file: IFile;
  fileId: string;
}

export interface IAfterSaleRequestStatusLine {
  id: string;
  creationTime: string;
  status: AfterSaleRequestStatusEnum;
  creatorUser: IUser;
  comment?: string;
}

export enum AfterSaleRequestStatusEnum {
  Draft = 1, // En cours
  Pending, // En cours de livraison
  Done, // Terminée
  Canceled, // Annulée
}

export const AfterSaleRequestStatusLabels = {
  [AfterSaleRequestStatusEnum.Draft]: "En attente de prise en charge",
  [AfterSaleRequestStatusEnum.Pending]: "Pris en charge",
  [AfterSaleRequestStatusEnum.Done]: "Terminée",
  [AfterSaleRequestStatusEnum.Canceled]: "Annulée",
};

export const AfterSaleRequestStatusColors = {
  [AfterSaleRequestStatusEnum.Draft]: "#A9ADAA",
  [AfterSaleRequestStatusEnum.Pending]: "#58B8DC",
  [AfterSaleRequestStatusEnum.Done]: "#4EE683",
  [AfterSaleRequestStatusEnum.Canceled]: "#DC5858",
};
export interface ICreateAfterSaleRequest
  extends Omit<IAfterSaleRequest, "id"> {}

export const defaultEntity: IAfterSaleRequest = {} as IAfterSaleRequest;

export const defaultCreateEntity: ICreateAfterSaleRequest = {
  status: AfterSaleRequestStatusEnum.Draft,
  statusLines: [],
  reference: "",
  creationTime: "",
  creatorUser: {} as IUser,
};

export const service = new AbpAppService<IAfterSaleRequest, IAfterSaleRequest>(
  "AfterSaleRequest"
);

export const validateAsync = (
  afterSaleRequest: IAfterSaleRequest
): Promise<AxiosResponse<IAbpResponseGet<IAfterSaleRequest>>> =>
  axios.post(
    `${ApiSettings.apiBaseUrl}${service.servicePath}${service.serviceName}/Validate`,
    afterSaleRequest,
    {
      headers: {
        ...ApiSettings.getDefaultHeaders(),
      },
    }
  );

export const refuseAsync = (
  afterSaleRequest: IAfterSaleRequest
): Promise<AxiosResponse<IAbpResponseGet<IAfterSaleRequest>>> =>
  axios.post(
    `${ApiSettings.apiBaseUrl}${service.servicePath}${service.serviceName}/Refuse`,
    afterSaleRequest,
    {
      headers: {
        ...ApiSettings.getDefaultHeaders(),
      },
    }
  );

export const addCommentAsync = (params: {
  afterSaleRequestId: string;
  comment: string;
}): Promise<AxiosResponse<IAbpResponseGet<IAfterSaleRequestStatusLine>>> =>
  axios.post(
    `${ApiSettings.apiBaseUrl}${service.servicePath}${service.serviceName}/AddComment`,
    params,
    {
      headers: {
        ...ApiSettings.getDefaultHeaders(),
      },
    }
  );

export const getStatistics = async (params: {}): Promise<
  IAbpResponseGet<IStatistics<AfterSaleRequestStatusEnum>>
> => {
  let result = {} as IAbpResponseGet<IStatistics<AfterSaleRequestStatusEnum>>;
  try {
    result = await service.invoke("get", "GetStatistics", params);
  } catch (error) {
    console.log(ApiSettings.getResponseExceptionMessage(error));
  }
  return result;
};
