import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { registerAsync } from "redux/actions/registration";
import { ILoadingType } from "types/ILoadingType";

export interface IRegistrationState {
  status: ILoadingType;
  canLogin: boolean;
  error?: string;
}

export const initialState: IRegistrationState = {
  status: ILoadingType.idle,
  canLogin: false,
  error: "",
};

const registrationSlice = createSlice({
  name: "registration",
  initialState: initialState, // for TypeScript check
  reducers: {
    reset: (state) => {
      let newState = { ...initialState };
      return newState;
    },
  },
  extraReducers: (builder) => {
    //registrationAsync
    builder.addCase(registerAsync.fulfilled, (state, action) => {
      state.status = ILoadingType.succeeded;
      state.canLogin = action.payload.result.canLogin;
    });
    builder.addCase(registerAsync.rejected, (state, action) => {
      state.status = ILoadingType.failed;
      state.error = action.error?.message;
      state.canLogin = false;
    });
    builder.addCase(
      registerAsync.pending,
      (state, action: PayloadAction<any>) => {
        state.status = ILoadingType.loading;
      }
    );
  },
});

export const { reset } = registrationSlice.actions;
export default registrationSlice.reducer;
