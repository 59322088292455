import { Route, Routes } from "react-router-dom";
import Login from "routes/login/Login";
import ScreenManager from "routes/app/ScreenManager";
import ForgotPassword from "routes/forgotPassword/ForgotPassword";
import Registration from "routes/registration/Registration";
import ResetPassword from "routes/resetPassword/ResetPassword";
import Image from "routes/image/Image";

const App = () => {
  return (
    <Routes>
      <Route path="/image" element={<Image />} />
      <Route path="/login" element={<Login />} />
      <Route path="/forgotPassword" element={<ForgotPassword />} />
      <Route path="/registration" element={<Registration />} />
      <Route path="/resetPassword" element={<ResetPassword />} />
      <Route path="*" element={<ScreenManager />} />
    </Routes>
  );
};

export default App;
